import { NgModule } from '@angular/core';
import {
  RouterModule,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
  ActivatedRoute,
} from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { WorkspaceGuardService } from '../core/auth/workspace.guard.service';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';

import { Root } from './menu';
import { routes } from './routes';

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  declarations: [],
  providers: [
    // ToasterService
  ],
  exports: [RouterModule],
})
export class RoutesModule {
  constructor(
    public menuService: MenuService,
    private router: Router,
    tr: TranslatorService,
    private route: ActivatedRoute,
    private wkService: WorkspaceGuardService,
  ) {
    // router.events.subscribe(r => console.log(r));
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        const wkCode = this.route.snapshot.params.wkcode; // this.route.snapshot.firstChild.url[1].path;
        if (wkCode) {
          this.wkService.setCurrentWorkspaceCode(wkCode);
        }
      }
    });
  }
}

export function redirectToAction(
  rejectedPermissionName: string,
  activateRouteSnapshot: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot,
) {
  return {
    navigationCommands: ['/pages/401'],
    // navigationExtras: {
    //     queryParams: { autoLogin: true , returnUrl: routerStateSnapshot.url }
    // }
  };
}
