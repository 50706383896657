import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuardService as AuthGuard } from '../core/auth/auth.guard';
import { HLayoutComponent } from '../layout/layout.component.h';
import { NgxPermissionsGuard } from 'ngx-permissions';
// import { FamilyComponent } from './family/family.component';

export const routes: Routes = [
  // { path: '', redirectTo: 'family/stanford', pathMatch: 'full' },
  { path: '', redirectTo: 'pages/404', pathMatch: 'full' },
  {
    path: ':wkcode/family',
    loadChildren: () =>
      import('./family/stanford/stanford.family.module').then(e => e.AppFamilyStanfordModule),
  },
  {
    path: ':wkcode/home',
    component: HLayoutComponent,
    loadChildren: () => import('./home/home.module').then(e => e.HomeModule),
  },
  { path: ':wkcode', redirectTo: ':wkcode/home/dashboard' },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'waitlist',
        loadChildren: () => import('./waitlist/waitlist.module').then(e => e.WaitListModule),
      },
      {
        path: ':wkcode/:list/entity/:code',
        loadChildren: () => import('./waitentity/waitentity.module').then(e => e.WaitEntityModule),
      },
      {
        path: ':wkcode/:list/stanford/entity/:code',
        loadChildren: () =>
          import('./stanfordentity/stanfordentity.module').then(e => e.StanfordEntityModule),
      },
      {
        path: ':wkcode/admin',
        loadChildren: () => import('./admin/admin.module').then(e => e.AdminModule),
      },
      {
        path: ':wkcode/report',
        loadChildren: () => import('./waitreport/waitreport.module').then(e => e.WaitReportModule),
      },
      {
        path: ':wkcode/smartlist',
        loadChildren: () =>
          import('./waitsmartlist/waitsmartlist.module').then(e => e.WaitSmartListModule),
      },
      {
        path: ':wkcode/:list/smartlist',
        loadChildren: () =>
          import('./waitsmartlist/waitsmartlist.module').then(e => e.WaitSmartListModule),
      },
    ],
  },
  {
    path: ':wkcode/parent',
    component: HLayoutComponent,
    loadChildren: () =>
      import('./family/default/family.module').then(e => e.AppFamilyDefaultModule),
  },
  {
    path: ':wkcode/parent/home',
    component: HLayoutComponent,
    loadChildren: () => import('./home/home.module').then(e => e.HomeModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(e => e.PagesModule),
  },
  {
    path: ':wkcode/pages',
    loadChildren: () => import('./pages/pages.module').then(e => e.PagesModule),
  },
  // Not found
  { path: '**', redirectTo: '/pages/404' },
  // Not lazy-loaded routes
];
